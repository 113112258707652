<!-- =========================================================================================
  Author: Nada Azzam
========================================================================================== -->

<template>
  <div id="data-list">
    <vs-popup
      classContent="popup-example"
      title="Confirm Update"
      :active.sync="popupActive"
    >
      <validation-observer ref="observer" v-slot="{ invalid }">
        <form>
          <ValidationProvider
            name="password"
            rules="required|min:8"
            v-slot="{ errors }"
          >
            <vs-input
              :danger="!!errors[0]"
              :danger-text="errors[0]"
              type="password"
              name="password"
              icon-no-border
              icon="icon icon-lock"
              icon-pack="feather"
              label-placeholder="Password"
              v-model="password"
              class="w-full mt-6"
            />
          </ValidationProvider>

          <vs-button
            :disabled="invalid"
            type="filled"
            @click="update"
            class="mb-2 mt-5"
            >Confirm</vs-button
          >
        </form>
      </validation-observer>
    </vs-popup>
    <vx-card
      ref="filterCard"
      title="Update Job Title"
      class="user-list-filters mb-8"
      collapse-action
      refresh-content-action
      @refresh="resetColFilters"
    >
      <validation-observer ref="observer" v-slot="{ invalid }">
        <form>
          <div class="vx-row">
            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
              <div class="vx-row">
                <div class="vx-col w-full mb-2">
                  <label class="text-sm opacity-75">
                    <span class="text-primary">*</span> NAME
                  </label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="name"
                    rules="required|min:2"
                  >
                    <vs-input
                      class="w-full"
                      icon-pack="feather"
                      icon="icon-terminal"
                      icon-no-border
                      :danger="!!errors[0]"
                      :danger-text="errors[0]"
                      v-model="name"
                    />
                  </validation-provider>
                </div>
                <div class="vx-col w-full mb-2">
                  <label class="text-sm opacity-75">
                    <span class="text-primary">*</span>Department
                  </label>
                  <validation-provider
                    name="department_id"
                    rules="required |min:1"
                  >
                    <v-select
                      label="name"
                      name="department_id"
                      :reduce="(name) => name.id"
                      :options="departments"
                      :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="department_id"
                      class="mb-4"
                      placeholder="Department"
                    />
                  </validation-provider>
                </div>
                <div class="vx-col w-full mb-2">
                  <label class="text-sm opacity-75">
                    <span class="text-primary">*</span>COMPANY
                  </label>
                  <validation-provider
                    name="company_id"
                    rules="required |min:1"
                  >
                    <v-select
                      label="name"
                      name="company_id"
                      :reduce="(name) => name.id"
                      :options="companies"
                      :clearable="false"
                      multiple
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="company_id"
                      class="mb-4"
                      placeholder="Company"
                    />
                  </validation-provider>
                </div>
              </div>
            </div>
          </div>

          <vs-button
            :disabled="invalid"
            type="filled"
            @click="openPopUp()"
            class="mb-2"
            >SUBMIT</vs-button
          >
        </form>
      </validation-observer>
    </vx-card>
  </div>
</template>

<script>
import axios from "@/axios.js";
import { required, min } from "vee-validate/dist/rules";
import vSelect from "vue-select";
import moduleCompaniesManagement from "@/store/get-all-companies/moduleCompaniesManagement.js";
import moduleDepartmentManagement from "@/store/get-all-departments/moduleDepartmentManagement.js";

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import endpoints from "../../../endpoints";

setInteractionMode("eager");
// betterEager
extend("required", {
  ...required,
  message: "field can not be empty",
});

extend("min", {
  ...min,
  message: "field may not be less than {length} characters",
});

// Store Module

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  watch: {
    department_id(obj) {
      // this.company_id = null;
      this.companies = [];
      if (obj) {
        this.$vs.loading();
        axios
          .get(`${endpoints.API_URL}department/${obj}/companies`)
          .then((response) => {
            this.$vs.loading.close();
            //  console.log(this.companies = response.data.response.data);
            this.companies = response.data.response.data;
          });
      }
    },
  },
  data() {
    return {
      name: null,
      company_id: null,
      password: null,
      popupActive: false,
      department_id: null,
      companies: [],
      // Cell Renderer Components
    };
  },
  computed: {
    departments() {
      // ////////console.log("hahahahahah : " + this.$store.state.company.items);
      return this.$store.state.department.items;
    },
  },

  methods: {
    resetColFilters(card) {
      card.removeRefreshAnimation(100);
      this.name = null;
    },
    openPopUp() {
      this.popupActive = true;
    },
    update() {
      this.$vs.loading();
      this.popupActive = false;

      const formData = new FormData();
      if (this.name) {
        formData.append("name", this.name);
      }
      if (this.company_id === []) {
         // console.log('done')
      } else {
          this.company_id.forEach((element, i) => {
        formData.append("companies[" + i + "]", element);
          });
      }
      if (this.department_id) {
        formData.append("department_id", this.department_id);
      }
      formData.append("password", this.password);
      formData.append("_method", "put");
      axios
        .post(
          `${endpoints.JOB_TITLES_ENDPOINT}/${this.$route.query.SID}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          this.$vs.loading.close();
          if (response.status !== 200) {
            this.$vs.notify({
              title: "Error",
              text: "Job is not updated",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Success",
              text: "Job is updated",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
            this.$router.push("/GetAll/Job");
          }
        })
        .catch((error) => {
          this.popupActive = false;

          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
    getById() {
      axios
        .get(`${endpoints.JOB_TITLES_ENDPOINT}/${this.$route.query.SID}`)
        .then((response) => {
          const res = response.data.response.data;
          this.name = res.name;
          // console.log(res.companies);
          this.company_id = res.companies.map((e) => e.id);
          this.department_id = res.department_id;
        });
    },
    getCompanies() {
      this.$store.dispatch("company/fetchItemsCompanies").then((response) => {
        this.$vs.loading.close();
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      });
    },
    getDepartments() {
      this.$store.dispatch("department/getAlls").then((response) => {
        this.$vs.loading.close();
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      });
    },
  },
  mounted() {
    this.getCompanies();
    this.getById();
    this.getDepartments();
  },
  created() {
    if (!moduleCompaniesManagement.isRegistered) {
      this.$store.registerModule("company", moduleCompaniesManagement);
      moduleCompaniesManagement.isRegistered = true;
    }
    if (!moduleDepartmentManagement.isRegistered) {
      this.$store.registerModule("department", moduleDepartmentManagement);
      moduleDepartmentManagement.isRegistered = true;
    }
  },
};
</script>

